import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import CoutDownTime from '../../components/count-down-time';
import FunToast from '../../components/fun-toast';
import Utils from '../../common/utils';
import model from './model';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

import './index.scss'

const Type = {
  Company: '1',
  Operation: '2',
};
export default class ForgetPwd extends Component {
  form = {
    mobile: '',
    otp: '',
    pwd: '',
    confirmPwd: '',
  }
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const type = query.get('type');
    const title = query.get('title') || '忘记密码';
    this.state = {
      mobile: '',
      pwd: '',
      otp: '',
      confirmPwd: '',
      type, // 1-企业 2-运营
      title,
    }
    this.sendOtpRef = React.createRef();
  }

  componentDidMount() {
    const { title } = this.state;
    eshiminSetTitleStyle(title);
  }

  onChangeField(field, value) {
    if (field === 'mobile') {
      this.form.mobile = value;
    } else if (field === 'otp') {
      this.form.otp = value;
    } else if (field === 'pwd') {
      this.form.pwd = value;
    } else if (field === 'confirmPwd') {
      this.form.confirmPwd = value;
    }
  }

  /**
   * 发送验证码
   */
  onSendOtp() {
    let { mobile } = this.form;
    let error = Utils.checkMobile(mobile);

    if (!error) {
      model.sendOtp({ mobile }).then(() => {
        this.sendOtpRef.current.startCountTime();
      });
    } else {
      FunToast.show(error);
    }
  }

  onCheckParam() {
    const { mobile, otp, pwd, confirmPwd } = this.form;

    if (!mobile) {
      return '请输入手机号码';
    }
    if (!otp) {
      return '请输入验证码';
    }
    if (!pwd) {
      return '请输入新密码';
    }
    let pwdError = Utils.checkPassword(pwd);
    if (pwdError) {
      return pwdError;
    }
    if (!confirmPwd) {
      return '请确认新密码';
    }
    let confirmPwdError = Utils.checkPassword(confirmPwd);
    if (confirmPwdError) {
      return confirmPwdError;
    }
    if (pwd !== confirmPwd) {
      return '两次输入的密码不一致';
    }
  }

  onConfirm() {
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }

    const { type } = this.state;
    type === Type.Company && model.resetPwd(this.form).then(() => {
      FunToast.show('修改成功');
      this.props.history.replace('./login');
    });

    type === Type.Operation && model.resetOperationPwd(this.form).then(() => {
      FunToast.show('修改成功');
      this.props.history.replace('./customer-list');
    });
  }

  render() {
    const { title } = this.state;
    return (
      <div className="forget-pwd-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            {title}
          </Header>

          <div className="forget-pwd-content-container">
            <FunCommonInput
              maxLength={11}
              type={'number'}
              dataType={EnDataType.none}
              placeholder={'请输入手机号'}
              icon={'icon-mobile'}
              onChange={(value) => this.onChangeField('mobile', value)}
            />

            <div className="forget-pwd-otp-container">
              <FunCommonInput
                maxLength={6}
                type={'number'}
                className={"forget-pwd-otp-item-container"}
                dataType={EnDataType.none}
                placeholder={'请输入验证码'}
                onChange={(value) => this.onChangeField('otp', value)}
              />
              <CoutDownTime
                ref={this.sendOtpRef}
                onClickSend={() => this.onSendOtp()}
                className={'forget-pwd-send-otp'}
                defaultText={'发送验证码'}
              />
            </div>

            <FunCommonInput
              className={"forget-pwd-item-container"}
              maxLength={12}
              dataType={EnDataType.none}
              placeholder={'请设置新密码'}
              onChange={(value) => this.onChangeField('pwd', value)}
            />
            <FunCommonInput
              className={"forget-pwd-item-container"}
              maxLength={12}
              dataType={EnDataType.none}
              placeholder={'请确认新密码'}
              onChange={(value) => this.onChangeField('confirmPwd', value)}
            />

            <FunButton
              className="forget-pwd-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onConfirm.bind(this)}
            >
              确定
            </FunButton>
          </div>

        </FunLayout>
      </div>
    )
  }
}
