import { request } from '../../http';
class Model {

  // 用户验证码登录
  loginOtp(data) {
    return request('/login/user', {
      method: 'POST',
      data,
      wholeResponse: true,
    });
  }

  // 发送验证码
  sendOtp(data) {
    return request(`index/sendCaptcha`, {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
