import { request } from '../../http';
class Model {

  // 用户登录
  login(data) {
    return request('/login/user', {
      method: 'POST',
      data,
      wholeResponse: true,
    });
  }

  // 判断用户是否有预约工单
  queryUserHasWorkOrder(data) {
    return request('/user/appointment/exist', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
