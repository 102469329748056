export default class LocalStore {
  static set(name, value) {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }

      window.localStorage.setItem(name, value);
    } catch (e) {}
  }

  static get(name) {
    let value = window.localStorage.getItem(name);

    try {
      if (value) {
        return JSON.parse(value);
      }
    } catch (e) {}

    return value;
  }

  static remove(name) {
    try {
      window.localStorage.removeItem(name);
    } catch (e) {}
  }

  static clear() {
    try {
      window.localStorage.clear();
    } catch (e) {}
  }
}