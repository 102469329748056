import React, { Component } from 'react';
import './index.scss';
import { filterChildren } from "../header";
import PropTypes from 'prop-types';
import FunDynamicCore from '../fun-dynamic-core'
import { EnDeviceAdaptiveType } from "../fun-device-adaptive";
import FunDeviceAdaptive from "../fun-device-adaptive";

function _console(text) {
  document.getElementById("console_test_id").innerText = text;
}

/**
 *  页面布局组件
 *  top表示顶部 bottom表示底部 其他的放在内容区域
 *  使用方法：
 *  <FunLayout>
 *    <div className='top'></div>
 *    <div>内容</div>
 *    <div className='bottom'></div>
 *  </FunLayout>
 *
 *
 */
export const EnLayoutType = {
  fixedLayout: 'fixed',
  staticLayout: 'static'
};

export default class FunLayout extends Component {

  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    topStyle: PropTypes.any,
    bottomStyle: PropTypes.any,

    isAdaptorKeyboard: PropTypes.bool, // 是否兼容键盘弹出

    isHideBody: PropTypes.bool
  };

  static defaultProps = {
    type: EnLayoutType.staticLayout,
    topStyle: {},
    bottomStyle: {},
    isAdaptorKeyboard: false,
    isHideBody: true
  };

  constructor(props) {
    super(props);
    this.topRef = React.createRef();
    this.bottomRef = React.createRef();
    this.mainContinerRef = React.createRef();

    this.state = {
      topHeight: null,
      bottomHeight: null,
      isKeyboard: false,
      pageHeight: this.getPageHeight()
    }
  }

  componentDidMount() {
    this.addStop(this.topRef.current);
    this.addStop(this.bottomRef.current);

    if (this.props.type === EnLayoutType.staticLayout && this.props.isHideBody) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      this.setViewSize();

      if (this.props.isAdaptorKeyboard) {
        window.addEventListener("resize", this.checkView.bind(this));
      }
    }
  }

  componentWillUnmount() {
    if (this.props.type === EnLayoutType.staticLayout) {
      document.body.style.overflow = 'visible';
      document.documentElement.style.overflow = 'visible';
    } else if (this.props.isAdaptorKeyboard) {
      window.removeEventListener("resize", this.checkView);
    }
  }

  checkView() {
    let newPageHeight = this.getPageHeight();

    if (newPageHeight + 150 < this.state.pageHeight) {
      if (!this.state.isKeyboard) {
        this.setState({
          isKeyboard: true
        });
      }
    } else if (this.state.isKeyboard) {
      this.setState({
        isKeyboard: false
      });
    }
  }

  getPageHeight() {
    return Math.max(document.documentElement.offsetHeight, document.documentElement.offsetWidth);
  }

  setViewSize() {
    let topHeight = this.topRef.current.firstChild.offsetHeight;
    let bottomHeight = this.bottomRef.current.firstChild.offsetHeight;

    this.setState({
      topHeight: topHeight,
      bottomHeight: bottomHeight
    });
  }

  addStop(wrapper) {
    let eventName = {
      start: 'touchstart',
      move: 'touchmove',
      end: 'touchend',
      cancel: 'touchcancel',
      scroll: 'scroll'
    };

    wrapper.addEventListener(eventName.move, (e) => {
      this.stop(e);
    });
  }

  stop(e) {
    _console('prevent');
    e.stopPropagation();
    e.preventDefault();
  }

  onLayoutClick(e) {
    const lowerNodeName = e.target.nodeName.toLowerCase + '';
    if (lowerNodeName === "input" || lowerNodeName === "textarea") {
      FunDynamicCore.blur();
    }
  }

  getContentHeight() {
    return this.mainContinerRef.current.offsetHeight;
  }

  render() {
    let topStyleNew, bottomStyleNew, bottomPartStyle;
    let { className, type, topStyle, bottomStyle } = this.props;
    let { topHeight, bottomHeight, isKeyboard } = this.state;

    className = `${className || ''} main-container ${type} ${isKeyboard ? 'focus-container' : ''}`;
    topStyleNew = { ...topStyle };
    bottomStyleNew = { ...bottomStyle };

    if (topHeight !== null) {
      topStyleNew.height = topHeight + 'px';
    }
    if (bottomHeight !== null && bottomHeight > 0) {
      bottomStyleNew.height = (bottomHeight + 10) + 'px';
    }

    // 键盘弹出来了 设置top
    if (isKeyboard) {
      bottomPartStyle = {
        bottom: 'auto',
        top: `${this.state.pageHeight - bottomHeight}px`
      }
    } else {
      bottomPartStyle = {
        bottom: '0',
        top: `auto`
      };
    }

    return (
      <div ref={this.mainContinerRef} onClick={(e) => this.onLayoutClick(e)} className={className} _fun-layout="true">
        <div className="table" _fun-layout="true">
          <div ref={this.topRef} className="tr _top-row" _fun-layout="true" style={topStyleNew}>
            <div className="_top-part" _fun-layout="true">
              {filterChildren(this.props.children, ['top'])}
            </div>
          </div>

          {this.props.type === EnLayoutType.fixedLayout && topHeight > 10 ? <FunDeviceAdaptive type={EnDeviceAdaptiveType.top} /> : null}


          <div className="tr _main-tr" _fun-layout="true">
            <div className="main-content" _fun-layout="true">
              {filterChildren(this.props.children, ['top', 'bottom'], true)}
            </div>
          </div>
          <div ref={this.bottomRef} className="tr _bottom-row" _fun-layout="true" style={bottomStyleNew}>
            <div className="_bottom-part" _fun-layout="true" style={bottomPartStyle}>
              {filterChildren(this.props.children, ['bottom'])}
            </div>
          </div>
        </div>
      </div>
    )
  }

}