/* eslint-disable no-undef */
import Platform from "./platform";

export function eshiminSetTitleStyle(title, titleColor = '#CBB486') {
  if (!Platform.isApp) return;
  title && eshimin.setTitle({
    title: title,
    onResponse: function (res) {
      console.log('setTitle res:' + res);
    }
  });
  titleColor && eshimin.setTitleColor({
    titleColor: titleColor,
    onResponse: function (res) {
      console.log('setTitleColor res:' + res);
    }
  });
}
