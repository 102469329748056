import React, { useEffect } from "react";
import FunCommonInput, { EnDataType } from "@/components/common-input";
import FunButton, { EnButtonSize, EnButtonType } from "@/components/fun-button";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import FunToast from "@/components/fun-toast";
import Header from "@/components/header";
import CoutDownTime from "@/components/count-down-time";
import Utils from "@/common/utils";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import model from "./model";
import "./index.scss";

export default function LoginOtp(props) {
  const form = {
    mobile: "",
    otp: "",
  };
  const sendOtpRef = React.createRef();
  const query = new URLSearchParams(props.location.search);
  const idx = query.get("idx");
  const isApplyer = query.get("isApplyer");
  const redirectUrl = query.get("redirectUrl") || "";
  const identity = query.get("identity");

  useEffect(() => {
    eshiminSetTitleStyle("登录");
  }, []);

  const onChangeField = (field, value) => {
    if (field === "mobile") {
      form.mobile = value;
    } else if (field === "otp") {
      form.otp = value;
    }
  };

  const onCheckParam = () => {
    const { mobile, otp } = form;
    if (!mobile) {
      return "请输入手机号码";
    }
    const error = Utils.checkMobile(mobile);
    if (error) {
      return error;
    }
    if (!otp) {
      return "请输入验证码";
    }
  };

  const onSendOtp = () => {
    let { mobile } = form;
    let error = Utils.checkMobile(mobile);

    if (!error) {
      model.sendOtp({ mobile }).then((res) => {
        console.log("otp:", res);
        sendOtpRef.current.startCountTime();
      });
    } else {
      FunToast.show(error);
    }
  };

  const onLogin = () => {
    const error = onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    const { mobile, otp } = form;
    model.loginOtp({ mobile, opt:otp }).then((res) => {
      const {
        data: { code },
      } = res;
      if (code === 200) {
        Utils.setLogin(true);
        props.history.replace(
          redirectUrl
            ? redirectUrl
            : `${isApplyer === "1" ? "upload-material" : "download-material"
            }?idx=${idx}&mobile=${mobile}&isApplyer=${isApplyer}&identity=${identity}`
        );
      }
    });
  };

  return (
    <div className="login-otp-container">
      <FunLayout
        type={EnLayoutType.fixedLayout}
        className={"main-content-p"}
        isAdaptorKeyboard={true}
      >
        <Header hidden={false} className={"top"} history={props.history}>
          手机验证码登录
        </Header>

        <div className="login-content-container">
          <FunCommonInput
            maxLength={11}
            type={"number"}
            dataType={EnDataType.none}
            placeholder={"请输入手机号"}
            icon={"icon-mobile"}
            onChange={(value) => onChangeField("mobile", value)}
          />
          <div className="login-otp-inner">
            <FunCommonInput
              maxLength={6}
              type={"number"}
              className={"login-otp-item-container"}
              dataType={EnDataType.none}
              placeholder={"请输入验证码"}
              onChange={(value) => onChangeField("otp", value)}
            />
            <CoutDownTime
              ref={sendOtpRef}
              onClickSend={onSendOtp}
              className={"login-send-otp"}
              defaultText={"发送验证码"}
            ></CoutDownTime>
          </div>

          <FunButton
            className="login-btn"
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={onLogin}
          >
            登录
          </FunButton>
        </div>
      </FunLayout>
    </div>
  );
}
