import React, { Component } from "react";
import PropTypes from "prop-types";

import FunIcon from "../fun-icon";
import FunDeviceAdaptive, { EnDeviceAdaptiveType } from "../fun-device-adaptive";
import FunDynamicCore from "../fun-dynamic-core";
import Platform from "../../common/platform";

import "./index.scss";

// 过滤掉子节点
export function filterChildren(children, classNameList, isNotEqual = false) {
  if (children) {
    // 转化为数组
    if (!(classNameList instanceof Array)) {
      classNameList = [classNameList];
    }

    if (children.map) {
      return children.map((child) => {
        return filterChild(child, classNameList, isNotEqual);
      });
    } else {
      return filterChild(children, classNameList, isNotEqual);
    }
  }

  function filterChild(child, classNameList, isNotEqual = false) {
    let isContain, childClassName, className;
    isContain = false;

    // 有可能是纯文本
    if (child && !!child.props) {
      childClassName = child.props.className || "";

      //遍历是否包含class
      for (let i = 0, l = classNameList.length; i < l; i++) {
        className = classNameList[i];
        if (childClassName.indexOf(className) !== -1) {
          isContain = true;
          break;
        }
      }
    }

    // 符合条件返回
    if ((!isNotEqual && isContain) || (isNotEqual && !isContain)) {
      return child;
    }
  }
}

/**
 *
 * 使用方法
 * <Header>
 *   <div class=''>
 *
 * </Header>
 *
 *
 */
export default class Header extends Component {
  static propTypes = {
    onClickBack: PropTypes.func,
    type: PropTypes.string,
    isInAppShow: PropTypes.bool, // 在app中是否需要显示
    hasBack: PropTypes.bool, // 是否有返回按钮
    isBack: PropTypes.bool, // 返回按钮是否触发返回 默认为true
    isNoBorder: PropTypes.bool, // 是否没有border
    hidden: PropTypes.bool,
  };

  static defaultProps = {
    type: "default", // default / apartment
    hasBack: false,
    isBack: true,
    isInAppShow: false, // 默认在app中不显示
    isNoBorder: false,
    hidden: false,
  };

  isShowHeader = !Platform.isApp;

  onClickBack() {
    if (this.props.isBack) {
      const canGoBack = (this.props.history.length > 1);
      setTimeout(() => {
        if (canGoBack) {
          this.props.history.goBack();
        } else {
          this.props.history.replace('./home');
        }
      }, 100);
    } else {
      FunDynamicCore.doCall(this.props.onClickBack);
    }
  }

  onClickRight() {
    FunDynamicCore.doCall(this.props.onClickRight);
  }

  render() {
    let $headerHeight = "50px";
    let $fontSize = "24px";
    let $marginLeft = "0px";
    let style = {
      lineHeight: $headerHeight,
      height: $headerHeight,
    };

    let backStyle = {
      width: $headerHeight,
      lineHeight: $headerHeight,
      height: $headerHeight,
      fontSize: $fontSize,
      marginLeft: $marginLeft,
    };

    let leftAndRightStyle = {
      minWidth: $headerHeight,
    };

    let rightStyle = {
      width: $headerHeight,
      lineHeight: $headerHeight,
      height: $headerHeight,
      fontSize: $fontSize,
    };

    if (this.props.hidden) {
      return null;
    }

    if (this.isShowHeader) {
      let headerClassName =
        "header " +
        (this.props.type + "-header") +
        (this.props.type === "default" ? " default" : this.props.type);

      return (
        <div className={this.props.type + "-header-container"} _header="true">
          <FunDeviceAdaptive type={EnDeviceAdaptiveType.top} color={"#fff"}>
            <div className={headerClassName} _header="true" style={style}>
              <div className="left" _header="true" style={leftAndRightStyle}>
                {filterChildren(this.props.children, "left")}
                {this.props.hasBack ? (
                  <div
                    className="back"
                    _header="true"
                    style={backStyle}
                    onClick={this.onClickBack.bind(this)}>
                    <FunIcon icon={"icon-angle-left"} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="title" _header="true">
                {filterChildren(this.props.children, ["left", "right"], true)}
              </div>

              <div className="right" _header="true" style={leftAndRightStyle}>
                {filterChildren(this.props.children, "right")}
                {this.props.hasRight ? (
                  <div
                    className="right"
                    _header="true"
                    style={rightStyle}
                    onClick={this.onClickRight.bind(this)}>
                    <FunIcon icon={this.props.iconRight} iconStyle={{ color: this.props.rightColor }} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.props.isNoBorder ? (
              ""
            ) : (
              <div className="line-bottom" style={{ height: "1px" }}></div>
            )}
          </FunDeviceAdaptive>
        </div>
      );
    } else {
      return null;
    }
  }
}
