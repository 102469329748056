import { Fragment } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login";
import LoginOtp from "./pages/login-otp";
import ForgetPwd from "./pages/forget-pwd";
import Register from "./pages/register";
import History from "./common/url/history";

import "./public/font/style.css";
import "./public/eshimin.js";
import "./App.scss";

function App() {
  return (
    <Fragment>
      <HashRouter history={History}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/login-otp" component={LoginOtp} />
          <Route path="/forget-pwd" component={ForgetPwd} />
          <Route path="/register" component={Register} />
          <Redirect to={"/login-otp"} />
        </Switch>
      </HashRouter>
    </Fragment>
  );
}

export default App;
