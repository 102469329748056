import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @type {{top: string, bottom: string}}
 * 适配的类型 顶部适配 底部适配
 */
export const EnDeviceAdaptiveType = {
  top: 'top',
  bottom: 'bottom',
  none: ""
};

export default class FunDeviceAdaptive extends Component {
  static propTypes = {
    type: PropTypes.string,
    color: PropTypes.string
  };

  static defaultProps = {
    type: EnDeviceAdaptiveType.top,
    color: 'transparent'
  };

  /**
   *
   * @returns {Promise<*|{bottomHeight: number, StatusBarHeight: number, ScreenWidth: number, ScreenHeight: number, PhoneModel: string}>}
   * 返回底部和顶部的高度
   */

  static system;
  static async getSystemInfo() {
    let data = FunDeviceAdaptive.system;

    if (!!data) {
      return data;
    }

    try {
      // data = await Tasks.getSystemInfo();

      if (data.TabBarHeight > 60) {
        data.bottomHeight = data.TabBarHeight - 60;
      }else {
        data.bottomHeight = 0;
      }

      // if (Utils.getClient().isAndroid && data.StatusBarHeight > 10) {
      //   data.StatusBarHeight = data.StatusBarHeight / (window.devicePixelRatio || 1);
      // }

    } catch (e) {
      data = {
        "bottomHeight": 0, // 手机底部高度
        "StatusBarHeight": 0, // 手机信号状态栏高度
        "ScreenWidth": 375,
        "ScreenHeight": 667,
        "PhoneModel": "iPhone 6 Plus" //手机型号
      };
    }
    return FunDeviceAdaptive.system = data;
  }

  constructor(props) {
    super(props);

    this.state = {
      paddingTop: "0",
      paddingBottom: "0",
      background: props.color
    };
    this.setSystemInfo().catch();
  }

  async setSystemInfo() {
    let data = await FunDeviceAdaptive.getSystemInfo();

    if (this.props.type === EnDeviceAdaptiveType.top) {
      this.setState({
        paddingTop: data.StatusBarHeight + 'px'
      });
    }else if(this.props.type === EnDeviceAdaptiveType.bottom){
      this.setState({
        paddingBottom: data.bottomHeight + 'px'
      });
    }
  }

  render() {
    return (<div style={this.state}>{this.props.children}</div>);
  }
}
